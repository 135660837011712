import React from "react";
import { graphql, Link } from "gatsby";
import * as styles from "../components/Secret.module.css";
import SecretLayout from "../components/SecretLayout";
import { H2 } from "../components/SecretComponents";

const Secret = ({ data }) => {
  const createDiaryLinks = (identifier) => {
    return (
      <ul className={styles.ul}>
        {data.allMdx.nodes.map((node) => {
          if (node.frontmatter.world === identifier) {
            return (
              <li key={node.id} className={styles.li}>
                <Link to={`/secret/${node.slug}`} className={styles.link}>
                  {node.slug}
                </Link>
              </li>
            );
          }
        })}
      </ul>
    );
  };
  return (
    <SecretLayout>
      <H2>あの頃</H2>
      {createDiaryLinks("there")}
      <H2>この頃</H2>
      <ul className={styles.ul} style={{ marginBottom: "20px" }}>
        <li className={styles.li}>Nothing here</li>
      </ul>
      <Link to="/" style={{ color: "#666666" }}>
        Back to Home
      </Link>
    </SecretLayout>
  );
};

export const query = graphql`
  {
    allMdx(filter: { fields: { sourceInstanceName: { eq: "diary" } } }) {
      nodes {
        frontmatter {
          world
        }
        slug
        id
      }
    }
  }
`;

export default Secret;
