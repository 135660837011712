import React from "react";
import * as styles from "../components/SecretComponents.module.css";

export const H1 = ({ children }) => {
  return <h1 className={styles.header}>{children}</h1>;
};

export const H2 = ({ children }) => {
  return <h2 className={styles.header}>{children}</h2>;
};
